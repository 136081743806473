import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
/* import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
 */import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AppRoutingModule],
  providers: [/* FCM, */ { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, SplashScreen],
  bootstrap: [AppComponent],
})
export class AppModule { }
