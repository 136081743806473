import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'presentation',
    loadChildren: () => import('./pages/presentation/presentation.module').then(m => m.PresentationPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./pages/auth/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'detail-inventary',
    loadChildren: () => import('./pages/modals/detail-inventary/detail-inventary.module').then(m => m.DetailInventaryPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'alta-herramienta',
    loadChildren: () => import('./pages/modals/alta-herramienta/alta-herramienta.module').then(m => m.AltaHerramientaPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
