import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
/* import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
 */import { DataStorageService } from "../app/services/data-storage.service";
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform/* , private fcm: FCM */, private splashScreen: SplashScreen,
    public dataStorageService: DataStorageService, public router: Router) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-H4P6Y08RWP',
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
      )
    this.initializeApp();


  }


  initializeApp() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);

/*       this.fcm.getToken().then(token => {
        console.log('tokentoken', token);
        this.dataStorageService.setFCMToken(token);

      });

      this.fcm.onNotification().subscribe((payload: object) => {
      }); */

    });

  }
}