import { Injectable } from '@angular/core';
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  userData: any;
  fcmToken: string;
  constructor(private storage: Storage) { }


  setUserData(data: any) {
    this.userData = data.response;
  }

  getUserData() {
    return this.userData;
  }

  setFCMToken(token: string) {
    this.fcmToken = token;
  }

  getFCMToken() {
    return this.fcmToken;
  }



}
